<template>
    <b-card title="Edit Employee">
        <b-row>
            <b-col md="3">
                <h2>{{ employeeDetails.employeeNameId }}</h2>
            </b-col>
        </b-row>
        <b-tabs>
            <b-tab title="Personal Detail">
                <validation-observer ref="userRole" tag="form">
                    <b-form>
                        <b-row>
                            <b-col md="3">
                                <b-form-group label="Customer Prefix">
                                    <v-select v-model="employeeDetails.namePrefix" :options="customerPrefixOption" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Customer Name *">
                                    <validation-provider #default="{ errors }" name="Customer Name" rules="required">
                                        <b-form-input v-model="employeeDetails.name"
                                            :state="errors.length > 0 ? false : null"
                                            placeholder="Please Enter Customer Name" />
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Mobile No *">
                                    <validation-provider #default="{ errors }" name="Mobile No"
                                        rules="required|regex:^[6789]\d{9}$">
                                        <b-form-input v-model="employeeDetails.mobileNo"
                                            :state="errors.length > 0 ? false : null"
                                            placeholder="Please Enter Mobile No" />
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Email">
                                    <b-form-input v-model="employeeDetails.emailId" placeholder="Please Enter Email" />
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group label="Address">
                                    <b-form-textarea v-model="employeeDetails.address" rows="2"
                                        placeholder="Please Enter Address" />
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group label="Area Location">
                                    <b-form-textarea v-model="employeeDetails.areaLocation" rows="2"
                                        placeholder="Please Enter Area Location" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="State">
                                    <b-form-input v-model="employeeDetails.state" placeholder="Please Enter State" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="PinCode">
                                    <b-form-input v-model="employeeDetails.pinCode"
                                        placeholder="Please Enter Pin Code" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Customer Preference">
                                    <v-select v-model="employeeDetails.customerPreference"
                                        placeholder="Select Preference" :options="customerPreferenceOption"
                                        label="name" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Default Discount (%)">
                                    <b-form-input v-model="employeeDetails.defaultDiscountPercentage"
                                        placeholder="Please Enter Discount (%)" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Communication Preference">
                                    <v-select v-model="employeeDetails.communicationPrefrence" label="name"
                                        placeholder="Select Communication Preference"
                                        :options="communicationPreferenceOption" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Customer GSTIN">
                                    <b-form-input v-model="employeeDetails.gstin"
                                        placeholder="Please Enter Customer GSTIN" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Membership Id">
                                    <b-form-input v-model="employeeDetails.membershipId"
                                        placeholder="Please Enter Membership Id" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Barcode">
                                    <b-form-input v-model="employeeDetails.barcode"
                                        placeholder="Please Enter Barcode" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Birthday">
                                    <flat-pickr v-model="employeeDetails.birthday" class="form-control" name="date"
                                        placeholder="Please Enter Birthday" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Anniversary Date">
                                    <flat-pickr v-model="employeeDetails.anniversary" class="form-control" name="date"
                                        placeholder="Please Enter Anniversary Date" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Remark">
                                    <b-form-input v-model="employeeDetails.remarks" placeholder="Please Enter Remark" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Home Delivery">
                                    <v-select v-model="employeeDetails.enabelHomeDelevery" label="name"
                                        placeholder="Select Home Delivery" :options="homeDeliveryOption" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Marketing Source">
                                    <b-form-input v-model="employeeDetails.marketingSource"
                                        placeholder="Please Enter Marketing Source" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Alternative Phone">
                                    <b-form-input v-model="employeeDetails.alternetPhone"
                                        placeholder="Please Enter Phone" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Facebook ID">
                                    <b-form-input v-model="employeeDetails.facebookId"
                                        placeholder="Please Enter Facebook ID" />
                                </b-form-group>
                            </b-col>
                            <b-col md="3">
                                <b-form-group label="Registration Source">
                                    <v-select v-model="employeeDetails.registrationSource" label="name"
                                        placeholder="Enter Registration Source" :options="registrationSourceOption" />
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group label="Message on Search Customer">
                                    <b-form-textarea v-model="employeeDetails.msgOnSearchCustomer" rows="2"
                                        placeholder="Please Enter Message on Search Customer" />
                                </b-form-group>
                            </b-col>
                            <b-col cols="6">
                                <b-form-group label="Message On Garment Delivery">
                                    <b-form-textarea v-model="employeeDetails.messageOnGarmentDelivery" rows="2"
                                        placeholder="Please Enter Message On Garment Delivery" />
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <hr>
                        <b-row>
                            <b-col class="text-center">
                                <b-button variant="primary" @click="updateEmployeeDetail">Update</b-button>
                            </b-col>

                        </b-row>
                    </b-form>
                </validation-observer>
            </b-tab>
            <b-tab title="Employee Detail">
                <b-form>
                    <b-row>
                        <b-col md=3>
                            <b-form-group label="Store Name">
                                <v-select :options="storeNameOptions" v-model="employeeDetails.storeName"
                                    label="storeName" placeholder="---Select Store---"></v-select>
                            </b-form-group>
                        </b-col>
                        <b-col md=3>
                            <b-form-group label="Bank AC Detail">
                                <b-form-input v-model="employeeDetails.bankDetail"
                                    placeholder="Enter Bank AC Detial"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md=3>
                            <b-form-group label="PAN No">
                                <b-form-input v-model="employeeDetails.panNo"
                                    placeholder="Enter PAN Detail"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md=3>
                            <b-form-group label="Aadhar No">
                                <b-form-input v-model="employeeDetails.adharDetail"
                                    placeholder="Enter Aadhar No"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md=3>
                            <b-form-group label="Emergency Contact No">
                                <b-form-input v-model="employeeDetails.emergencyContactNo"
                                    placeholder="Enter Emergency Contact No"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md=3>
                            <b-form-group label="Barcode Id">
                                <b-form-input v-model="employeeDetails.barcodeId"
                                    placeholder="Enter Barcode Id"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md=3>
                            <b-form-group label="Salary">
                                <b-form-input v-model="employeeDetails.salary"
                                    placeholder="Enter Salary"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md=3>
                            <b-form-group label="Qualification">
                                <b-form-input v-model="employeeDetails.qualification"
                                    placeholder="Enter Qualification"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md=3>
                            <b-form-group label="Joining Date">
                                <flat-pickr v-model="employeeDetails.joinDate" class="form-control" name="date"
                                    placeholder="Please Enter Joining Date" />
                            </b-form-group>
                        </b-col>
                        <b-col md=3>
                            <b-form-group label="Job Type">
                                <b-form-input v-model="employeeDetails.jobType"
                                    placeholder="Enter Job Type"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md=3>
                            <b-form-group label="Department">
                                <b-form-input v-model="employeeDetails.department"
                                    placeholder="Enter Department"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md=3>
                            <b-form-group label="Experience Details">
                                <b-form-input v-model="employeeDetails.experienceDetails"
                                    placeholder="Enter Experience Details"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md=3>
                            <b-form-group label="Leaving Reason">
                                <b-form-input v-model="employeeDetails.leavingReason"
                                    placeholder="Enter Leaving Reason"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md=3>
                            <b-form-group label="Leaving Date">
                                <flat-pickr v-model="employeeDetails.leavingDate" class="form-control" name="date"
                                    placeholder="Please Enter Leaving Date" />
                            </b-form-group>
                        </b-col>
                        <b-col md=3>
                            <b-form-group label="Blood Group">
                                <b-form-input v-model="employeeDetails.bloodGroup"
                                    placeholder="Enter Blood Group"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md=3>
                            <b-form-group label="Service Type">
                                <b-form-input v-model="employeeDetails.serviceType"
                                    placeholder="Enter Service Type"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md=3>
                            <b-form-group label="Remarks">
                                <b-form-input v-model="employeeDetails.empRemarks"
                                    placeholder="Enter Remarks"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col md=3>
                            <b-form-group>
                                <b-form-checkbox v-model="employeeDetails.active"
                                    class="mt-2">IsActive</b-form-checkbox>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                        <b-col class="text-center">
                            <b-button variant="primary" @click="updateEmployeeDetail">Update</b-button>
                        </b-col>

                    </b-row>
                </b-form>
            </b-tab>
            <b-tab title="User Role">
                <b-form>
                    <b-row>
                        <b-col>
                            <b-form-group>
                                <b-form-checkbox-group class="demo-inline-spacing" v-model="employeeDetails.userRole"
                                    :options="userRoleOptions" text-field="name" value-field="name" />
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <hr>
                    <b-row>
                        <b-col class="text-center">
                            <b-button variant="primary" @click="updateRoles(employeeDetails.userRole)">Update
                                Roles</b-button>
                        </b-col>

                    </b-row>
                </b-form>
            </b-tab>
        </b-tabs>

    </b-card>
</template>

<script>
import {
    BRow,
    BCol, BCard,
    BFormGroup,
    BFormInput, BForm,
    BFormCheckboxGroup, BFormTextarea, BButton, BFormCheckbox, BTabs, BTab
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import api from '@/store/api'
import util from '@/store/utils'

export default {
    components: {
        BCard, BFormTextarea,
        BFormCheckbox,
        BRow,
        BCol,
        BFormGroup, BForm,
        BFormInput,
        vSelect,
        BFormCheckboxGroup, BTabs, BTab,
        flatPickr, BButton, ValidationProvider, ValidationObserver
    },
    props: {
        employeeDetails: Array,
        getEmployeeList: Function
    },
    data() {
        return {
            id: 0,
            customerPrefixOption: ['Mr', 'Mrs', 'Ms', 'Dr', 'M/S', 'Pastor', 'Deacon', 'Cheif', 'VIP', 'ADV'],
            customerPrefix: 'Mr',
            customerName: '',
            mobileNo: '',
            emailId: '',
            address: '',
            areaLocation: '',
            state: '',
            pinCode: '',
            customerPreferenceOption: [],
            customerPreference: null,
            defaultDiscountPercentage: 0,
            communicationPreferenceOption: util.communicationPreferenceOptions,
            communicationPrefrence: null,
            gstin: '',
            membershipId: '',
            barcode: '',
            birthday: '',
            anniversary: '',
            remarks: '',
            homeDeliveryOption: ['Yes', 'No'],
            homeDelivery: '',
            enabelHomeDelevery: false,
            marketingSource: '',
            alternetPhone: '',
            facebookId: '',
            registrationSourceOption: [
                { 'id': 1, 'name': 'Direct' },
                { 'id': 2, 'name': 'Marketing' },
                { 'id': 3, 'name': 'Google' },
                { 'id': 4, 'name': 'Other' }
            ],
            registrationSource: null,
            messageOnSearchCustomer: '',
            messageOnGarmentDelivery: '',


            employeeId: 0,
            storeNameOptions: [],
            storeName: '',
            bankDetail: '',
            panDetail: '',
            adharDetail: '',
            emergencyContactNo: '',
            barcodeId: '',
            salary: '',
            qualification: '',
            joiningDate: '',
            jobType: '',
            department: '',
            leavingReason: '',
            leavingDate: '',
            bloodGroup: '',
            serviceType: '',
            employeeRemarks: '',
            isActive: false,




            userRoleOptions: [],
            userRole: [],

        }
    },
    methods: {
        updateRoles(userRole) {
            let self = this;
            var axios = require('axios');
            let userRolesList = [];
            for (let index = 0; index < userRole.length; index++) {
                const object = self.userRoleOptions.find(obj => obj.name == userRole[index]);
                userRolesList.push({ 'description': object.description, 'id': object.id, 'name': object.name });

            }
            var data = JSON.stringify(
                {
                    "employeeId": self.employeeDetails.empId,
                    "roles": userRolesList,
                    "userId": self.employeeDetails.id
                });
            axios(api.getApi('Put', '/setEmployeesRoles', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.getEmployeeList();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        updateEmployeeDetail() {
            let self = this;
            var axios = require('axios');
            if (self.employeeDetails.communicationPreference == null) {
                self.employeeDetails.communicationPreference = null
            } else {
                self.employeeDetails.communicationPreference = { 'id': self.employeeDetails.communicationPreference.id, 'name': self.employeeDetails.communicationPreference.name };
            }

            if (self.employeeDetails.customerPreference == null) {
                self.employeeDetails.customerPreference = null
            } else {
                self.employeeDetails.customerPreference = { 'id': self.employeeDetails.customerPreference.id, 'name': self.employeeDetails.customerPreference.name };
            }

            if (self.employeeDetails.enabelHomeDelevery == 'Yes') {
                self.homeDelivery = true;
            } else {
                self.homeDelivery = false;
            }
            if (self.employeeDetails.registrationSource > 0) {
                const object = self.registrationSourceOption.find(obj => obj.id == self.employeeDetails.registrationSource);
                self.employeeDetails.registrationSource = { 'id': object.id, 'name': object.name };
            }
            if (self.employeeDetails.registrationSource == 0) {
                self.employeeDetails.registrationSource = 0;
            }
            var data = JSON.stringify(
                {

                    "user": {
                        "id": self.employeeDetails.id,
                        "namePrefix": self.employeeDetails.namePrefix,
                        "name": self.employeeDetails.name,
                        "mobileNo": self.employeeDetails.mobileNo,
                        "emailId": self.employeeDetails.emailId,
                        "address": self.employeeDetails.address,
                        "areaLocation": self.employeeDetails.areaLocation,
                        "state": self.employeeDetails.state,
                        "pinCode": self.employeeDetails.pinCode,
                        "customerPreference": self.employeeDetails.customerPreference,
                        "defaultDiscountPercentage": self.employeeDetails.defaultDiscountPercentage,
                        "communicationPrefrence": self.employeeDetails.communicationPrefrence,
                        "gstin": self.employeeDetails.gstin,
                        "membershipId": self.employeeDetails.membershipId,
                        "barcode": self.employeeDetails.barcode,
                        "birthday": self.employeeDetails.birthday,
                        "anniversary": self.employeeDetails.anniversary,
                        "remarks": self.employeeDetails.remarks,
                        "enabelHomeDelevery": self.homeDelivery,
                        "marketingSource": self.employeeDetails.marketingSource,
                        "alternetPhone": self.employeeDetails.alternetPhone,
                        "facebookId": self.employeeDetails.facebookId,
                        "registrationSource": self.employeeDetails.registrationSource.id,
                        "msgOnSearchCustomer": self.employeeDetails.msgOnSearchCustomer,
                        "msgOnGarmentDelevery": self.employeeDetails.messageOnGarmentDelivery,
                    },
                    "store": {
                        "id": self.employeeDetails.storeName.id,
                        "storeName": self.employeeDetails.storeName.storeName,
                    },
                    "id": self.employeeDetails.empId,
                    "bankDetail": self.employeeDetails.bankDetail,
                    "panDetail": self.employeeDetails.panNo,
                    "adharDetail": self.employeeDetails.adharDetail,
                    "emergencyContactNo": self.employeeDetails.emergencyContactNo,
                    "barcodeId": self.employeeDetails.barcodeId,
                    "salary": self.employeeDetails.salary,
                    "qualification": self.employeeDetails.qualification,
                    "joinDate": self.employeeDetails.joinDate,
                    "jobType": self.employeeDetails.jobType,
                    "department": self.employeeDetails.department,
                    "experienceDetails": self.employeeDetails.experienceDetails,
                    "leavingReason": self.employeeDetails.leavingReason,
                    "leavingDate": self.employeeDetails.leavingDate,
                    "booldGroup": self.employeeDetails.bloodGroup,
                    "serviceType": self.employeeDetails.serviceType,
                    "remarks": self.employeeDetails.empRemarks,
                    "active": self.employeeDetails.active
                }
            );
            axios(api.getApi('Put', '/employees/' + self.employeeDetails.empId, data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.getEmployeeList();
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getUserRole() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/users/getAllRoles', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.userRoleOptions = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getCustomerPreferences() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/customerPreferences', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.customerPreferenceOption = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        // getCommunicationPreference() {
        //     let self = this;

        //     var axios = require('axios');
        //     var data = '';
        //     axios(api.getApi('get', '/masters/communicationPreferences', data))
        //         .then(function (response) {
        //             //console.log(JSON.stringify(response.data));

        //             self.communicationPreferenceOption = response.data;
        //         })
        //         .catch(function (error) {
        //             console.log(error);
        //         });
        // },
        getStoreName() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/stores', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.storeNameOptions = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getEmployeeDetails(id) {
            let self = this;
            var axios = require('axios');
            var data = '';

            axios(api.getApi('get', '/getEmployee/' + id, data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    if (response.data.user.customerPreference == null) {
                        self.customerPreference = '';
                    } else {
                        self.customerPreference = response.data.user.customerPreference;
                    }
                    self.defaultDiscountPercentage = response.data.user.defaultDiscountPercentage;
                    if (response.data.user.communicationPrefrence == null) {
                        self.communicationPreference = '';
                    } else {
                        self.communicationPreference = response.data.user.communicationPrefrence;
                    }
                    if (response.data.user.enabelHomeDelevery == true) {
                        self.homeDelivery = 'Yes';
                    } else {
                        self.homeDelivery = 'No';
                    }
                    if (response.data.user.registrationSource > 0) {
                        const object = self.registrationSourceOption.find(obj => obj.id == response.data.user.registrationSource);
                        self.registrationSource = { 'id': object.id, 'name': object.name };
                    }
                    if (response.data.registrationSource == 0) {
                        self.registrationSource = 0;
                    }
                    let userRoleName = [];
                    for (let index = 0; index < response.data.user.roles.length; index++) {
                        userRoleName.push(response.data.user.roles[index].name)

                    }

                    let employeeNameId=0;
                    if (util.digits_count(id) == 3) {
                        employeeNameId = "0" + id;
                    } else if (util.digits_count(id) == 2) {
                        employeeNameId = "00" + id;
                    }
                    else if (util.digits_count(id) == 1) {
                        employeeNameId = "000" + id;
                    }

                    self.employeeDetails = {
                        'id': response.data.user.id,
                        'namePrefix': response.data.user.namePrefix,
                        'name': response.data.user.name,
                        'mobileNo': response.data.user.mobileNo,
                        'emailId': response.data.user.emailId,
                        'address': response.data.user.address,
                        'areaLocation': response.data.user.areaLocation,
                        'state': response.data.user.state,
                        'pinCode': response.data.user.pinCode,
                        'customerPreference': self.customerPreference,
                        'defaultDiscountPercentage': response.data.user.defaultDiscountPercentage,
                        'communicationPrefrence': self.communicationPreference,
                        'gstin': response.data.user.gstin,
                        'membershipId': response.data.user.membershipId,
                        'barcode': response.data.user.barcode,
                        'birthday': response.data.user.birthday,
                        'anniversary': response.data.user.anniversary,
                        'remarks': response.data.user.remarks,
                        'enabelHomeDelevery': self.homeDelivery,
                        'marketingSource': response.data.user.marketingSource,
                        'alternetPhone': response.data.user.alternetPhone,
                        'facebookId': response.data.user.facebookId,
                        'registrationSource': self.registrationSource,
                        'msgOnSearchCustomer': response.data.user.msgOnSearchCustomer,
                        'messageOnGarmentDelivery': response.data.user.msgOnGarmentDelevery,
                        'empId': response.data.id,
                        'employeeNameId':employeeNameId,
                        'storeName': response.data.store.storeName,
                        'bankDetail': response.data.bankDetail,
                        'panNo': response.data.panDetail,
                        'adharDetail': response.data.adharDetail,
                        'emergencyContactNo': response.data.emergencyContactNo,
                        'barcodeId': response.data.barcodeId,
                        'salary': response.data.salary,
                        'qualification': response.data.qualification,
                        'joinDate': response.data.joinDate,
                        'jobType': response.data.jobType,
                        'department': response.data.department,
                        'employeeDetails': response.data.employeeDetails,
                        "experienceDetails": response.data.experienceDetails,
                        'leavingReason': response.data.leavingReason,
                        'leavingDate': response.data.leavingDate,
                        'bloodGroup': response.data.booldGroup,
                        'serviceType': response.data.serviceType,
                        'empRemarks': response.data.remarks,
                        'active': response.data.active,
                        'userRole': userRoleName
                    }
                    

                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    },
    created() {
        let self = this;
        if (self.$route.params.id > 0) {
            self.id = self.$route.params.id;
            self.getEmployeeDetails(self.$route.params.id)
        }
        self.getCustomerPreferences();
        // self.getCommunicationPreference();
        self.getStoreName();
        self.getUserRole();
    }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style>
.demo-inline-spacing {
    display: block;
}
</style>
